import * as React from "react"
import Layout from "../components/layout"
import { Container, Row, Col} from "react-bootstrap"
import { Helmet } from 'react-helmet';

// markup
const ConsultingPage = () => {

  return (
    <Layout>
      <Helmet>
        <title>Consulting - Unternehmensgruppe Urbas</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12} className="overflow-hidden" style={{height: '25vh'}}>
            <div className="bg-dark-primary parallelogram-header1-sub">
              <div className="unskew-header1-sub">
                <div id="rect-sub">
                  <div className="p-3">
                    <h1>
                      Consulting
                      <br />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="pt-3 p-0 ">
        <div className="bg-ultralight m-1">
          <div className="container-fluid py-4 text-left" style={{maxWidth: 1000}}>
            <Row>
              <Col>
                <h4>Firmenprofil</h4>
                <div className="p-2">
                  <p>
                    Die zunehmende Digitalisierung, Vernetzung und nicht zuletzt
                    die rasante Entwicklungen der Computertechnik und des Internets
                    stellen viele Unternehmen  nahezu täglich vor neue Herausforderungen.
                    Um Unternehmen auch bei der Bewältigung dieser Anforderungen im Zusammenhang
                    mit dieser steten Transformation zu Unterstützen, wurde das
                    <b className="font-primary">Unternehmen Urbas Business & Consulting</b> gegründet.
                  </p>
                  <br></br>
                  <p>
                    <b className="font-primary">Urbas Business & Consulting</b> steht seinen Kunden dabei als kompetentes
                    IT-Dienstleistungsunternehmen als Berater und Prozesspartner zur Seite
                  </p>
                  <br></br>
                  <p>
                    Unser Leistungsspektrum geht dabei über die bloße Beratung weit hinaus.
                    So agieren wir als spezialisierter IT-Dienstleister, der komplexe Geschäftsprozesse,
                    in maßgeschneiderten, offenen, unternehmensübergreifenden IT-Lösungen abbildet.
                    Inzwischen ist <b className="font-primary">Urbas Business & Consulting</b> ein gefragter Partner im Automotive
                    Umfeld mit Schwerpunkt in den Bereichen Analyse und Verifikation komplexer IT-Themen
                    im Werkstattumfeld namhafter Premiumhersteller.
                    Wir planen, entwickeln, integrieren und unterstützen anspruchsvolle Projekte im Automotive Umfeld.
                    Gerne stellen wir Ihnen das Know-how unserer IT-Spezialisten und Ingenieure
                    als Outsourcing-Partner zur Verfügung oder wir benutzen unsere weitreichenden Kontakte
                    um den richtigen Mitarbeiter für Sie zu finden.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Container className="pt-3 p-0 pb-4">
        <div className="bg-ultralight m-1 p-1">
          <div className="container-fluid py-4 text-left" style={{maxWidth: 1000}}>
              <Row>
                  <Col>
                    <h4>Leistungen</h4>
                    <div className="p-2">
                      <ul>
                        <li>Engineering</li>
                        <li>Laboraufbau</li>
                        <li>Prozessanalyse</li>
                        <li>Projektmanagement</li>
                        <li>Qualitätssicherung</li>
                        <li>Softwareanalyse</li>
                        <li>Softwareentwicklung</li>
                        <li>Systemadministration</li>
                      </ul>
                    </div>
                  </Col>
                <Col>
                    <h4>Referenzen</h4>
                      <div className="p-2">
                        <ul>
                          <li>BMW AG</li>
                          <li>BMW M Sport GmbH</li>
                          <li>BMW Motorrad GmbH</li>
                          <li>Auto König GmbH</li>
                          <li>Harman  Becker Automotive Systems GmbH</li>
                          <li>IFS Informationstechnik GmbH</li>
                          <li>Toyota Deutschland GmbH</li>
                        </ul>
                      </div>
                  </Col>
              </Row>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ConsultingPage
